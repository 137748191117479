import React, { useState } from "react"
import LayoutLogged from "../../components/layout/logged"
import Container from '@material-ui/core/Container';
import { useForm } from "react-hook-form";
import AppForm from "../../components/form/app-form";
import UserFormAdmin from "../../components/pages/user-form-admin";
import { ifNotHaveAccessRedirect } from "../../services/auth";

export default function UserCreate() {
  const { control, handleSubmit, setValue, formState: { errors } } = useForm();
  const [ errorsApi, setErrorsApi ] = useState(undefined);

  if (ifNotHaveAccessRedirect('user.write')) return <></>;

  return (
    <LayoutLogged title="Criar um usuário">
      <Container>
        <AppForm
          api="/user"
          linksPath="/user"
          handleSubmit={handleSubmit}
          onErrors={setErrorsApi}
          control
        >
          <UserFormAdmin
            control={control}
            setValue={setValue}
            errorsForm={errors}
            errorsApi={errorsApi}
          />
        </AppForm>
      </Container>
    </LayoutLogged>
  );
}