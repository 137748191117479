import React, { useEffect, useState } from "react"
import UserForm from "./user-form";
import ControllerAutocomplete from "../form/controller-autocomplete";
import { FormControlLabel, Switch } from "@material-ui/core";
import { getCurrentUser, hasPermission } from "../../services/auth";

export default function UserFormAdmin({
  control,
  setValue,
  errorsForm,
  errorsApi,
  user,
  disabled,
}) {
  const [isResponsible, setIsResponsible] = useState(user?.organization_responsible);
  const [isReferrer, setIsReferrer] = useState(user?.roles?.find(role => role.name === 'referrer') !== undefined);
  const [organizationId, setOrganizationId] = useState(user?.organization_id);
  const [isInternalUser, setIsInternalUser] = useState();

  useEffect(() => {
    setValue('organization_responsible', user?.organization_responsible);
    setValue('roles', user?.roles.map(role => role.name));
  }, [user]);

  useEffect(() => {
    if (!organizationId) return;

    const currentUser = getCurrentUser();

    setIsInternalUser(currentUser.organization_id === organizationId);
  }, [organizationId]);

  return (
    <>
      <UserForm
        control={control}
        errorsForm={errorsForm}
        errorsApi={errorsApi}
        user={user}
        setValue={setValue}
        disabled={disabled}
      />

      <ControllerAutocomplete
        control={control}
        errorsForm={errorsForm}
        errorsApi={errorsApi}
        setValue={(key, value) => {
          setValue(key, value);

          if (key === 'organization_id') {
            setOrganizationId(value);
          }
        }}
        data={user}
        name="organization_id"
        label="Organização"
        apiSearchName="name"
        api="/organization"
        disabled={disabled}
        required
      />

      {isInternalUser && (
        <ControllerAutocomplete
          control={control}
          errorsForm={errorsForm}
          errorsApi={errorsApi}
          setValue={setValue}
          data={user}
          name="roles"
          fieldId="name"
          label="Permissões"
          apiSearchName="name"
          api="/role"
          multiple
          required={false}
          disabled={disabled}
        />
      )}

      {isInternalUser === false && (
        <FormControlLabel control={
          <Switch
            disabled={disabled}
            checked={isReferrer}
            onChange={(e) => {
              if (e.target.checked) {
                setValue('roles', ['referrer']);
              } else {
                setValue('roles', []);
              }

              setIsReferrer(e.target.checked)
            }}
          />
        } label="Esse parceiro irá participar do sistema de indicação?" />
      )}

      {hasPermission('user.organization_responsible.write') && (
        <FormControlLabel control={
          <Switch
          disabled={disabled}
            checked={isResponsible}
            onChange={(e) => {
              setValue('organization_responsible', e.target.checked);
              setIsResponsible(e.target.checked)
            }}
          />
        } label="Usuário é responsável pela organização?" />
      )}
    </>
  );
}
